import TextField from 'client/widget-components/components/TextField';
import { TextCustomizationType } from 'client/widget-components/ecom/stores/types';
import React, { FC, memo } from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import { ProductCustomizationsStyles } from '../RuntimeProductCustomizationsTypes';
import {
    Label,
    TextareaWithCustomPlaceholder,
} from './TextFieldsCustomComponents';
import { MAX_TEXT_FIELD_LENGTH, TEXT_FIELD_DATA_GRABS } from './constants';

interface RuntimeProductCustomizationTextFieldProps
    extends TextCustomizationType {
    value?: string;
    styles?: ProductCustomizationsStyles;
    onChange: (fieldId: string, value: string) => void;
}

const RuntimeProductCustomizationTextField: FC<
    RuntimeProductCustomizationTextFieldProps
> = ({ id, label, hint, title, value = '', styles, onChange }) => {
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(id, event.target.value.substring(0, MAX_TEXT_FIELD_LENGTH));
    };

    return (
        <TextField
            label={label}
            placeholder={hint}
            styles={{
                label: styles?.inputLabel,
                counter: styles?.inputCharacterCounter,
                textFieldWrapper: textFieldWrapperStyles,
            }}
            dataAuto='product-customizations-text-field'
            dataGrabs={TEXT_FIELD_DATA_GRABS}
            labelElement={
                <Label
                    styles={{
                        inputLabel: styles?.inputLabel,
                        inputCharacterCounter: styles?.inputCharacterCounter,
                    }}
                    label={label}
                    valueLength={value.length}
                />
            }
            textareaElement={
                <TextareaWithCustomPlaceholder
                    name={title}
                    id={id}
                    value={value}
                    placeholder={hint}
                    styles={{
                        textarea: styles?.input,
                        placeholder: styles?.inputPlaceholder,
                    }}
                    data-grab={TEXT_FIELD_DATA_GRABS?.textareaDataGrab}
                    increaseSpecificity
                    onChange={handleChange}
                />
            }
        />
    );
};

const textFieldWrapperStyles = {
    [RuntimeMediaQuery.COMMON]: {
        gap: '4px',
    },
};

export default memo(RuntimeProductCustomizationTextField);
